import React, { useEffect, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

import LoadingZone from 'components/LoadingZone';
import { fnChildrenPropType } from 'utils/customPropTypes';
import { gtag } from 'utils/common';
import { determineIdpFromCognitoUsername } from './helpers';

const UserAuthManager = ({ children }) => {
  const fnNavigate = useNavigate();
  const [bIsAuthenticating, fnSetIsAuthenticating] = useState(true);
  const [oUser, fnSetUser] = useState(null);

  useEffect(() => {
    const fnUpdateUser = async (oAuthData) => {
      try {
        const sEvent = oAuthData.payload.event;
        const oCognitoUser = await Auth.currentAuthenticatedUser({
          bypassCache: sEvent === 'wfuForceTokenRefresh',
        });
        const bIsFirstTimeUser =
          oCognitoUser.signInUserSession.idToken.payload.isFtu === 'true';
        const sIdp = determineIdpFromCognitoUsername(oCognitoUser.username);

        switch (sEvent) {
          // Page load - user could already be signed in
          case 'wfuInitialAuth':
            if (!bIsFirstTimeUser) {
              fnSetUser(oCognitoUser);
            }

            fnSetIsAuthenticating(false);
            break;

          // User signed in
          case 'signIn':
            if (bIsFirstTimeUser) {
              fnNavigate('/sign-up');
            } else {
              fnSetUser(oCognitoUser);
              gtag('event', 'login', {
                method: sIdp,
              });
            }

            fnSetIsAuthenticating(false);
            break;

          // Handle redirect after successful sign in
          case 'customOAuthState':
            if (oCognitoUser && !bIsFirstTimeUser) {
              const sDecoded = decodeURIComponent(oAuthData.payload.data);
              const oCustomState = JSON.parse(sDecoded);
              if (oCustomState.redirect) {
                fnNavigate(oCustomState.redirect);
              }
            }
            break;

          // User successfully completed the sign up process
          case 'wfuForceTokenRefresh':
            fnSetIsAuthenticating(true);
            fnSetUser(oCognitoUser);

            gtag('event', 'sign_up', {
              method: sIdp,
            });

            fnNavigate('/main');
            fnSetIsAuthenticating(false);
            break;

          case 'signOut':
            fnSetUser(null);
            break;

          default:
            break;
        }
      } catch (error) {
        if (error !== 'The user is not authenticated') {
          console.error('Error authenticating user', error);
        }
        fnSetIsAuthenticating(false);
      }
    };

    // Subscribe to AWS Amplify auth events
    Hub.listen('auth', fnUpdateUser);

    // Subscribe to custom WFU events
    Hub.listen('wfu', fnUpdateUser);

    // Check if the user is already logged in when the page loads,
    // because we won't get a signIn event.
    Hub.dispatch('wfu', {
      event: 'wfuInitialAuth',
      data: {},
      message: '',
    });

    return () => {
      Hub.remove('auth', fnUpdateUser);
      Hub.remove('wfu', fnUpdateUser);
    };
  }, []);

  return (
    <LoadingZone isLoading={bIsAuthenticating}>
      {children({ oUser })}
    </LoadingZone>
  );
};

UserAuthManager.propTypes = {
  children: fnChildrenPropType.isRequired,
};

export default UserAuthManager;
