import React, { useRef } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';

import { TextField, TextArea } from 'components/Form/Fields';
import oWnApi from 'utils/WnApi';
import GoogleReCaptcha from 'components/Form/Fields/GoogleReCaptcha';
import { fnSendFormSubmitEventToGoogleTagManager } from 'utils/common';
import helpRequestSchema from './schemas';

const oInitialValues = {
  firstname: '',
  lastname: '',
  email: '',
  comments: '',
  reCaptchaToken: window.Cypress ? 'cypress' : '',
};

const sFormName = 'Contact Us';

const SignUpHelpForm = ({
  sFormId,
  fnSetIsSubmitting,
  fnSetIsSuccessState,
}) => {
  const reCaptchaRef = useRef();
  const fnHandleSubmit = async (oFormValues, oFormikBag) => {
    fnSetIsSubmitting(true);

    fnSendFormSubmitEventToGoogleTagManager(sFormName, sFormId);

    const oRequestPayload = { ...oFormValues, contactmethod: 'email' };
    const oResult = await oWnApi.post('contact_us', oRequestPayload, false);
    if (oResult.status === 200) {
      fnSetIsSuccessState(true);
    } else if (oResult.data.includes('reCAPTCHA')) {
      console.error('reCAPTCHA verification failed');
      oFormikBag.setFieldValue('reCaptchaToken', '');
      if (reCaptchaRef.current) {
        reCaptchaRef.current.reset();
      }
    }

    fnSetIsSubmitting(false);
  };

  return (
    <Formik
      onSubmit={fnHandleSubmit}
      initialValues={oInitialValues}
      validateOnBlur
      validationSchema={helpRequestSchema}
    >
      {() => (
        <Form className='help__form' name={sFormName} id={sFormId}>
          <TextField sLabel='First Name' sName='firstname' sId='first-name' />
          <TextField sLabel='Last Name' sName='lastname' sId='last-name' />
          <TextField
            sLabel='Email'
            sName='email'
            sPlaceholder='you@example.com'
            sId='email'
          />
          <TextArea sId='comments' sName='comments' sLabel='Comments' />
          <GoogleReCaptcha oReCaptchaRef={reCaptchaRef} />
        </Form>
      )}
    </Formik>
  );
};

SignUpHelpForm.propTypes = {
  sFormId: PropTypes.string.isRequired,
  fnSetIsSubmitting: PropTypes.func.isRequired,
  fnSetIsSuccessState: PropTypes.func.isRequired,
};

export default SignUpHelpForm;
