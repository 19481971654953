import React from 'react';

import usePageTitle from 'utils/hooks/usePageTitle';
import AuthLayout from '../../AuthLayout';
import TermsOfUseAcceptanceForm from './TermsOfUseAcceptanceForm';
import tosLogo from '../../images/tos.svg';

const sFormId = 'terms-of-use-form';

const TermsOfUseAcceptancePage = () => {
  usePageTitle('Sign Up');

  return (
    <AuthLayout
      continueButton={
        <button
          type='submit'
          form={sFormId}
          className='authLayout__continueBtn'
        >
          Continue
        </button>
      }
      left={
        <>
          <h2>It&apos;s you!</h2>
          <p className='termsOfUseAcceptancePg__subheading'>
            The last step is to agree to the terms of service and then
            you&apos;re done.
          </p>
          <TermsOfUseAcceptanceForm sFormId={sFormId} />
        </>
      }
      right={
        <img
          src={tosLogo}
          className='termsOfUseAcceptancePg__tosDocumentLogo'
          alt='a terms of service document'
        />
      }
    />
  );
};

export default TermsOfUseAcceptancePage;
