import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';

import { TextArea } from 'components/Form/Fields';
import oWnApi from 'utils/WnApi';
import PageLayout from 'components/Layout/PageLayout';
import { SubmitButton } from 'components/Form/Buttons';
import usePageTitle from 'utils/hooks/usePageTitle';
import { fnSendFormSubmitEventToGoogleTagManager } from 'utils/common';
import contactUsSchema from './schemas';
import BestEmailToContactYouField from './BestEmailToContactYouField';

const oInitialValues = {
  email: '',
  comments: '',
};

const sFormName = 'Contact Us';
const sFormId = 'contact-us-form';

const ContactUs = () => {
  const oProfileData = useSelector((state) => state.Profile || {});
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessState, setIsSuccessState] = useState(false);

  usePageTitle('Contact Us');

  const handleSubmit = async (oFormData) => {
    setIsLoading(true);
    fnSendFormSubmitEventToGoogleTagManager(sFormName, sFormId);
    const oRequestPayload = {
      firstname: (oProfileData.BIOGRAPHICAL || {}).FIRSTNAME || '',
      lastname: (oProfileData.BIOGRAPHICAL || {}).LASTNAME || '',
      email: oFormData.email,
      comments: oFormData.comments,
      contactmethod: 'email',
    };
    const oResponse = await oWnApi.post('contact_us', oRequestPayload);
    if (oResponse.status === 200) {
      setIsSuccessState(true);
    }
  };

  return (
    <PageLayout sTitle='Contact Us'>
      <div className='contactUs__formWrapper'>
        {isSuccessState && (
          <>
            <h2 className='authHelpForm__successHeading'>
              Request Successfully Sent
            </h2>
            <p className='authHelpForm__successText'>
              Someone from Wake Forest will be in contact with you soon!
            </p>
          </>
        )}
        {!isSuccessState && (
          <Formik
            onSubmit={handleSubmit}
            initialValues={oInitialValues}
            validateOnBlur
            validationSchema={contactUsSchema}
          >
            {() => (
              <Form name={sFormName} id={sFormId}>
                <h2 className='helpHeading'>
                  Having trouble? We&apos;re here to help.
                </h2>
                <p>
                  Please share a bit more information here, and we&apos;ll reach
                  out to you soon!
                </p>
                <div className='authHelpForm__fieldsWrapper'>
                  <TextArea sId='comments' sName='comments' sLabel='Comments' />
                  <BestEmailToContactYouField />
                </div>
                <SubmitButton>Send</SubmitButton>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </PageLayout>
  );
};

export default ContactUs;
