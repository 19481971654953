import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';

import { currentUserLookupIdSelector } from 'redux/selectors';
import oWnApi from 'utils/WnApi';
import Button from 'components/Buttons/Button';
import { SubmitButton } from 'components/Form/Buttons';
import LoadingZone from 'components/LoadingZone';
import { fnSendFormSubmitEventToGoogleTagManager } from 'utils/common';
import Portal from '../../../../../utils/Portal';
import FileUpload from '../../../../Form/Fields/FileUpload';
import profilePhotoSchema from './schema';
import useCloseOnOutsideClick from '../../../../../utils/hooks/useCloseOnOutsideClick';
import * as oProfileActions from '../../../redux/actions';

const sFormName = 'Upload Profile Photo';
const sFormId = 'profile-photo-upload-form';

const PhotoUploadModal = ({ fnSetUploadedImage, setShowChangePhotoModal }) => {
  const changePhotoModalRef = useRef();
  const fileUploadFieldRef = useRef();
  const sLookupId = useSelector(currentUserLookupIdSelector);
  const fnDispatch = useDispatch();
  const [bIsLoading, fnSetIsLoading] = useState(false);

  const handleSubmit = async (values, formikBag) => {
    fnSendFormSubmitEventToGoogleTagManager(sFormName, sFormId);
    const photoPayload = {
      image: values.photoUpload,
    };
    try {
      const result = await oWnApi.post(
        `constituents/${sLookupId}/photo`,
        photoPayload
      );
      if (result.status === 200) {
        fnSetUploadedImage(
          `data:image/jpeg;base64,${result.data.DATA.PICTURE}`
        );
        setShowChangePhotoModal(false);
        // Update the HAS_PHOTO boolean in Redux
        await new Promise((resolve, reject) => {
          fnDispatch(
            oProfileActions.get({
              data: {
                lookupId: sLookupId,
              },
              resolve,
              reject,
            })
          );
        });
      }
    } catch (err) {
      console.error('Error uploading profile photo', err);
      formikBag.setFieldError(
        'photoUpload',
        'There was a problem uploading your photo. Please try again or select another photo.'
      );
    }
  };

  // Move tab focus inside modal when it opens
  useEffect(() => {
    if (fileUploadFieldRef.current) {
      fileUploadFieldRef.current.focus();
    }
  }, [fileUploadFieldRef]);

  useCloseOnOutsideClick(changePhotoModalRef, setShowChangePhotoModal);

  return (
    <Portal>
      <div className='photoUploadModal' ref={changePhotoModalRef}>
        <div>
          <Formik
            initialValues={{ photoUpload: '' }}
            validationSchema={profilePhotoSchema}
            validateOnBlur={false}
            onSubmit={handleSubmit}
          >
            {(formikProps) => (
              <Form
                className='photoUploadModal__form'
                name={sFormName}
                id={sFormId}
              >
                <FileUpload
                  name='photoUpload'
                  label='Choose Photo'
                  acceptedFileMIMETypes={['image/jpeg', 'image/png']}
                  fieldRef={fileUploadFieldRef}
                  fnSetIsLoading={fnSetIsLoading}
                />
                <LoadingZone isLoading={bIsLoading}>
                  <SubmitButton
                    bIsCentered
                    bIsDisabled={!!formikProps.errors.photoUpload}
                  />
                </LoadingZone>
              </Form>
            )}
          </Formik>

          <Button
            fnHandleClick={() => setShowChangePhotoModal(false)}
            sFlavor='secondary'
            className='photoUploadModal__cancel'
            bIsCentered
          >
            Cancel
          </Button>
        </div>
      </div>
    </Portal>
  );
};

PhotoUploadModal.propTypes = {
  setShowChangePhotoModal: PropTypes.func.isRequired,
  fnSetUploadedImage: PropTypes.func.isRequired,
};

export default PhotoUploadModal;
