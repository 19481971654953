import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import ProfileSkeleton from 'components/MyProfile/elements/ProfileSkeleton';
import { currentUserLookupIdSelector } from 'redux/selectors';
import ButtonWithIcon from 'components/Buttons/ButtonWithIcon';
import IconOnlyButton from 'components/Buttons/IconOnlyButton';
import useIsUserAuthenticated from 'utils/hooks/useIsUserAuthenticated';
import Highlight from 'components/Layout/Highlight';
import { profileExternalViewActions } from './redux/actions';
import Portal from '../../utils/Portal';
import Header from '../MyProfile/elements/Header';
import Career from './elements/Career';
import Personal from './elements/Personal';
import Education from './elements/Education';
import LoadingZone from '../LoadingZone';
import useCloseOnOutsideClick from '../../utils/hooks/useCloseOnOutsideClick';
import { currentUserPublicProfileSelector } from './helpers';

/**
 * Displays a constituent's public profile as a modal.
 */
const ProfileExternalView = () => {
  const dispatch = useDispatch();
  const fnNavigate = useNavigate();
  const oParams = useParams();
  const bIsAuthenticated = useIsUserAuthenticated();
  const profile = useSelector((state) => state.ProfileExternalView);
  const oUserPublicProfile = useSelector(currentUserPublicProfileSelector);
  const sUserLookupId = useSelector(currentUserLookupIdSelector);
  const [bIsProfileLoaded, fnSetIsProfileLoaded] = useState(false);
  const modalRef = useRef();
  const oCloseButtonRef = useRef();
  const sRequestedLookupId = oParams.lookupId;
  const location = useLocation();

  // Fetch profile data
  useEffect(() => {
    if (!bIsAuthenticated || !sUserLookupId) {
      return;
    }
    const bIsUserViewingOwnProfile = sUserLookupId === sRequestedLookupId;
    if (bIsUserViewingOwnProfile) {
      dispatch(profileExternalViewActions.set(oUserPublicProfile));
      fnSetIsProfileLoaded(true);
    } else {
      const promise = new Promise((resolve, reject) => {
        dispatch(
          profileExternalViewActions.get({
            lookupId: sRequestedLookupId,
            resolve,
            reject,
          })
        );
      });
      promise.then(() => {
        fnSetIsProfileLoaded(true);
      });
    }
  }, [
    sRequestedLookupId,
    sUserLookupId,
    oUserPublicProfile,
    dispatch,
    bIsAuthenticated,
  ]);

  // Move focus into the modal for users navigating the site via keyboard
  useEffect(() => {
    oCloseButtonRef?.current?.focus();
  }, [oCloseButtonRef]);

  const goBackToOriginalPage = () => {
    fnNavigate(-1);
  };

  useCloseOnOutsideClick(modalRef, goBackToOriginalPage);

  const hasPersonalData =
    profile?.PHONE?.length > 0 ||
    profile?.EMAIL?.length > 0 ||
    profile?.ADDRESS?.length > 0;
  const hasCareerData =
    profile?.EMPLOYMENT?.length > 0 || profile?.JOB_SPECIALTY?.length > 0;
  const hasEducData = profile?.DEGREE?.length > 0;

  const bShowBackBtn = location.state?.from.includes('profile');

  return (
    <Portal>
      <div className='modalOverlay'>
        <div
          className='profileExternalViewModal'
          role='dialog'
          aria-modal='true'
          aria-label={`${
            ((profile || {}).BIOGRAPHICAL || {}).FORMATTEDNAME
          } Profile`}
          ref={modalRef}
        >
          <div
            className={`profileExternalView__nav ${
              bShowBackBtn ? 'profileExternalView__nav--withBackBtn' : ''
            }`}
          >
            {bShowBackBtn && (
              <ButtonWithIcon
                sIcon='arrowLeft'
                fnHandleClick={() => fnNavigate(-1)}
              >
                Back to previous profile
              </ButtonWithIcon>
            )}
            <IconOnlyButton
              sIcon='close'
              fnHandleClick={goBackToOriginalPage}
              sAriaLabel='Close'
              oRef={oCloseButtonRef}
            />
          </div>
          {!bIsAuthenticated && (
            <Highlight sFlavor='info'>
              Please sign in to view this profile.
            </Highlight>
          )}
          <LoadingZone
            isLoading={!bIsProfileLoaded}
            message='Loading profile'
            skeleton={<ProfileSkeleton />}
          >
            <>
              <Header data={profile} bIsPublicMode />
              {hasCareerData && <Career />}
              {hasPersonalData && <Personal />}
              {hasEducData && <Education />}
            </>
          </LoadingZone>
        </div>
        )
      </div>
    </Portal>
  );
};

export default ProfileExternalView;
