/**
 * Determine the IDP name from the Cognito username.
 *
 * @param username - Cognito username
 * @returns - IDP name
 */
export const determineIdpFromCognitoUsername = (username: string): string => {
  let sIdp = '';
  switch (true) {
    case username.includes('Google'):
      sIdp = 'Google';
      break;
    case username.includes('Amazon'):
      sIdp = 'Amazon';
      break;
    case username.includes('Apple'):
      sIdp = 'Apple';
      break;
    default:
      sIdp = 'Other';
      break;
  }
  return sIdp;
};

export default {};
