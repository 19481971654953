import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { CancelButton, SubmitButton } from 'components/Form/Buttons';
import { yupSchemaPropType } from 'utils/customPropTypes';
import { TextArea } from '../../../Fields';
import { NOTE as noteSchema } from './schemas';

/**
 * @description - A field that allows the user to submit a brief message about
 *                a section of their profile
 * @param {*} {
 *   id, primary, label,
 * }
 */
const AddNote = ({
  visible,
  onSubmit,
  status,
  setFormStatus,
  successMsg,
  initialValue,
  classname,
  textAreaClassName,
  showCancel,
  onClickCancel,
  submitButtonText,
  validationSchema,
  sNotePrefix,
  sNoteFieldId,
  sFormName,
  sFormId,
}) => {
  const initialValues = {
    TEXTNOTE: initialValue,
  };
  const handleSubmit = async (formData, formActions) => {
    const submitPromise = new Promise((resolve, reject) => {
      const oProcessedData = { ...formData };
      if (sNotePrefix) {
        oProcessedData.TEXTNOTE = `${sNotePrefix} ${oProcessedData.TEXTNOTE}`;
      }
      onSubmit({ data: oProcessedData, resolve, reject });
    });
    try {
      const result = await submitPromise;
      if (result === 'Success') {
        formActions.setSubmitting(false);
        setFormStatus('success');
      } else {
        console.warn('[W] Failed to submit note: ', result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useLayoutEffect(() => {
    if (visible) {
      const element = document.getElementsByClassName('addNoteCallout');
      if (element.length > 0) {
        element[0].scrollIntoView({ block: 'nearest' });
      }
    }
  });

  return (
    visible && (
      <div className={classname}>
        {status === 'success' ? (
          <p className='addNoteCallout__successMsg'>{successMsg}</p>
        ) : (
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            validateOnBlur={false}
          >
            {(oFormikProps) => (
              <Form name={sFormName} id={sFormId}>
                <TextArea
                  sId={sNoteFieldId}
                  className={textAreaClassName}
                  sName='TEXTNOTE'
                  sLabel='Note'
                />
                <div className='addNote__buttonWrapper'>
                  <SubmitButton>{submitButtonText}</SubmitButton>
                  {showCancel && (
                    <CancelButton
                      fnHandleClick={onClickCancel}
                      bIsDisabled={oFormikProps.isSubmitting}
                    />
                  )}
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    )
  );
};

AddNote.defaultProps = {
  visible: false,
  onSubmit: () => {},
  status: '',
  setFormStatus: () => {},
  successMsg:
    'Thank you! We are reviewing your note and will get back to you shortly.',
  initialValue: '',
  classname: 'addNoteCallout',
  textAreaClassName: '',
  showCancel: false,
  onClickCancel: () => {},
  submitButtonText: 'Send',
  validationSchema: noteSchema,
  sNoteFieldId: 'add-note',
  sNotePrefix: '',
  sFormName: 'Add Note',
  sFormId: 'add-note-form',
};

AddNote.propTypes = {
  visible: PropTypes.bool,
  onSubmit: PropTypes.func,
  status: PropTypes.string,
  setFormStatus: PropTypes.func,
  successMsg: PropTypes.string,
  initialValue: PropTypes.string,
  classname: PropTypes.string,
  textAreaClassName: PropTypes.string,
  showCancel: PropTypes.bool,
  onClickCancel: PropTypes.func,
  submitButtonText: PropTypes.string,
  validationSchema: yupSchemaPropType,
  sNoteFieldId: PropTypes.string,
  sNotePrefix: PropTypes.string,
  sFormName: PropTypes.string,
  sFormId: PropTypes.string,
};

export default AddNote;
