import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import IconOnlyButton from 'components/Buttons/IconOnlyButton';
import useCloseOnOutsideClick from '../../../../../../utils/hooks/useCloseOnOutsideClick';
import { sortByKey } from '../../../../../../utils/common';
import SortButton from './SortButton';

const SortingModal = ({ dataToSort, setSortedData, className }) => {
  const [showSortModal, setShowSortModal] = useState(false);
  const [activeSortOptions, setActiveSortOptions] = useState({
    key: 'SCORE',
    order: false, // true = ascending, false = descending
  });
  const sortingModalRef = useRef();

  const handleSortButtonClick = (newSortKey) => {
    let newSortOrder = activeSortOptions.order;
    const shouldReverseSortOrder = newSortKey === activeSortOptions.key;
    if (shouldReverseSortOrder) {
      newSortOrder = !activeSortOptions.order;
    }
    setActiveSortOptions({
      key: newSortKey,
      order: newSortOrder,
    });
    setShowSortModal(false);
  };

  // Sort the data
  useEffect(() => {
    setSortedData((currentData) => {
      const copyDataToSort = JSON.parse(JSON.stringify(currentData));
      const result = sortByKey(
        copyDataToSort,
        activeSortOptions.key,
        activeSortOptions.order
      );
      return result;
    });
  }, [activeSortOptions, setSortedData]);

  useCloseOnOutsideClick(sortingModalRef, setShowSortModal);

  return (
    <div className={`sortModalContainer ${className}`}>
      <IconOnlyButton
        sFlavor='tertiary'
        sIcon='ellipsis'
        sAriaLabel='Toggle sorting menu'
        fnHandleClick={() => setShowSortModal(!showSortModal)}
      />
      {showSortModal && (
        <div
          className='sortMenu'
          ref={sortingModalRef}
          role='dialog'
          aria-modal='true'
          aria-labelledby='sortingModalTitle'
        >
          <div className='t-paragraph--medium' id='sortingModalTitle'>
            Sort By:
          </div>
          <SortButton
            label='Last Name'
            sortKey='LASTNAME'
            handleClick={handleSortButtonClick}
            activeSortKey={activeSortOptions.key}
            activeSortOrder={activeSortOptions.order}
          />
          <SortButton
            label='First Name'
            sortKey='FIRSTNAME'
            handleClick={handleSortButtonClick}
            activeSortKey={activeSortOptions.key}
            activeSortOrder={activeSortOptions.order}
          />
          {dataToSort.length < 2000 && (
            <SortButton
              label='Score'
              sortKey='SCORE'
              handleClick={handleSortButtonClick}
              activeSortKey={activeSortOptions.key}
              activeSortOrder={activeSortOptions.order}
            />
          )}
          <SortButton
            label='City'
            sortKey='CITY'
            handleClick={handleSortButtonClick}
            activeSortKey={activeSortOptions.key}
            activeSortOrder={activeSortOptions.order}
          />
          <SortButton
            label='State'
            sortKey='STATE'
            handleClick={handleSortButtonClick}
            activeSortKey={activeSortOptions.key}
            activeSortOrder={activeSortOptions.order}
          />
          <SortButton
            label='Employer'
            sortKey='EMPLOYER'
            handleClick={handleSortButtonClick}
            activeSortKey={activeSortOptions.key}
            activeSortOrder={activeSortOptions.order}
          />
        </div>
      )}
    </div>
  );
};

SortingModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  dataToSort: PropTypes.array,
  setSortedData: PropTypes.func,
  className: PropTypes.string,
};

SortingModal.defaultProps = {
  dataToSort: [],
  setSortedData: () => {},
  className: '',
};

export default SortingModal;
