import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';

import { currentUserLookupIdSelector } from 'redux/selectors';
import { SubmitButton } from 'components/Form/Buttons';
import { fnSendFormSubmitEventToGoogleTagManager } from 'utils/common';
import useActions from '../../../../utils/hooks/useActions';
import * as actions from '../../../MyProfile/redux/actions';
import emailActivationSchema from './schemas';
import AlumniGoogleAccountFormBody from './AlumniGoogleAccountFormBody';
import { fnFormatAlumniGoogleAccountData, oInitialFormValues } from './helpers';

const sFormName = 'Activate Alumni Google Account';
const sFormId = 'alumni-google-account-activation-form';

const AlumniGoogleAccountActivationForm = () => {
  const profileActions = useActions(actions);
  const [isSuccessState, setIsSuccessState] = useState(false);
  const oProfile = useSelector((state) => state.Profile);
  const sLookupId = useSelector(currentUserLookupIdSelector);

  const handleFormSubmit = async (formData, formActions) => {
    fnSendFormSubmitEventToGoogleTagManager(sFormName, sFormId);
    try {
      // Create the new alumni Google account
      const oFormattedData = fnFormatAlumniGoogleAccountData(
        formData,
        oProfile
      );
      const sResponse = await new Promise((resolve, reject) => {
        profileActions.signUp({
          data: oFormattedData,
          resolve,
          reject,
        });
      });

      // If successful, get the updated profile data
      if (sResponse === 'Success') {
        await new Promise((resolve, reject) => {
          profileActions.get({
            data: {
              lookupId: sLookupId,
            },
            resolve,
            reject,
          });
        });
        setIsSuccessState(true);
      }
    } catch (oError) {
      console.error(oError);
      if (oError.message.includes('Entity already exists')) {
        formActions.setFieldError('username', 'Username already exists');
      }
    }
  };

  return isSuccessState ? (
    <div className='emailActivation__formIntro'>
      <h3 className='emailActivation__formTitle'>Woohoo! You&apos;re in!</h3>
      <p className='emailActivation__formDescrip'>
        You can now access Wake Network any time with your alumni username and
        password.
      </p>
    </div>
  ) : (
    <div className='emailActivation__form'>
      <Formik
        initialValues={oInitialFormValues}
        validationSchema={emailActivationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={handleFormSubmit}
      >
        {() => (
          <Form name={sFormName} id={sFormId}>
            <div className='emailActivation__formIntro'>
              <p className='emailActivation__formDescrip'>
                Create your own alumni username and password here! Your username
                will become the prefix to your new alumni Gmail account (i.e.
                jane.smith@alumni.wfu.edu). If you forget your username and/or
                password in the future, it will be sent to your recovery email.
              </p>
            </div>
            <AlumniGoogleAccountFormBody />
            <SubmitButton>Activate my Alumni Gmail</SubmitButton>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AlumniGoogleAccountActivationForm;
