import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import usePageTitle from 'utils/hooks/usePageTitle';
import ContinueButton from 'components/Authentication/AuthLayout/ContinueButton';
import AuthLayout from '../../AuthLayout';
import MatchForm from './MatchForm';
import MatchList from './MatchList';

import wnSearchingLogo from '../../images/ftu-search.svg';
import wnMatchListLogo from '../../images/match-list.svg';

/**
 * A form that handles matching a potentially new user to an existing dad record.
 * Displays and processes appropriate user inputs based on match type prop.
 */

const UserMatching = () => {
  const oParams = useParams();
  const fnNavigate = useNavigate();
  const sMatchType = oParams.type;
  const [aMatches, setMatches] = useState(null);
  const [sSelectedConstituent, fnSetSelectedConstituent] = useState(null);
  const [oNavigationParams, fnSetNavigationParams] = useState(null);

  usePageTitle('Sign Up');

  const handleMatchClick = (oConstituent) => {
    fnSetSelectedConstituent(oConstituent.LOOKUPID);

    switch (true) {
      case oConstituent.HASLASTFOUR === '1':
      case oConstituent.HASBANNERID === '1':
      case oConstituent.HASTEMPTOKEN === '1':
        fnSetNavigationParams({
          path: `/sign-up/validation/${sMatchType}`,
          state: { oConstituent },
        });
        break;
      case oConstituent.ISMAGICLINKELIGIBLE === '1':
        fnSetNavigationParams({
          path: `/sign-up/magic-link/${sMatchType}`,
          state: { oConstituent },
        });
        break;
      default:
        fnSetNavigationParams({ path: `/sign-up/help/${sMatchType}` });
        break;
    }
  };
  const fnHandleContinue = () => {
    fnNavigate(oNavigationParams.path, {
      state: oNavigationParams.state,
    });
  };
  const fnHandleClearSearch = () => {
    setMatches(null);
    fnSetSelectedConstituent(null);
  };

  return (
    <AuthLayout
      sMatchType={sMatchType}
      continueButton={
        <ContinueButton
          fnHandleContinue={fnHandleContinue}
          bContinueButtonDisabled={sSelectedConstituent === null}
        />
      }
      left={
        <>
          {!aMatches && (
            <MatchForm setMatches={setMatches} matchType={sMatchType} />
          )}
          {aMatches && (
            <MatchList
              aMatches={aMatches}
              handleMatchClick={handleMatchClick}
              sSelectedConstituentLookupId={sSelectedConstituent}
              fnHandleClearSearch={fnHandleClearSearch}
            />
          )}
        </>
      }
      right={
        <div className='matching__searchingRightContainer'>
          <img
            src={aMatches ? wnMatchListLogo : wnSearchingLogo}
            className={
              aMatches ? 'matching__matchListLogo' : 'matching__searchingLogo'
            }
            alt='Wake Network searching logo'
          />
        </div>
      }
    />
  );
};

export default UserMatching;
