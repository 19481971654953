import React from 'react';
import { Form, Formik } from 'formik';
import { Hub } from 'aws-amplify';

import useWindowSize from 'utils/hooks/useWindowSize';
import Accordion from 'components/Layout/Accordion';
import TermsOfUse from './TermsOfUse';
import { CheckBox } from '../../../Form/Fields';
import termsSchema from './schema';

const oInitialValues = {
  terms: false,
};

/**
 * Displays form asking user to accept the terms of service.
 *
 * Note: We are not tracking in DAD if a user accepts the terms of service.
 */
const TermsOfUseAcceptanceForm = ({ sFormId }) => {
  const oWindowSize = useWindowSize();
  const bIsMobile = oWindowSize.width < 640;

  const fnHandleSubmit = () => {
    // Refresh current session since first-time users already logged in
    Hub.dispatch('wfu', {
      event: 'wfuForceTokenRefresh',
      data: {},
      message: '',
    });
  };

  return (
    <Formik
      onSubmit={fnHandleSubmit}
      initialValues={oInitialValues}
      validateOnBlur
      validationSchema={termsSchema}
    >
      {() => (
        <Form
          className='termsOfUseAcceptancePg__form'
          name='Terms of Use'
          id={sFormId}
        >
          <p className='termsOfUseAcceptancePg__formCheckBoxWrapper'>
            <CheckBox
              sId='terms'
              sName='terms'
              sLabel='I agree to the Terms of Service'
            />
          </p>
          {!bIsMobile ? (
            <TermsOfUse />
          ) : (
            <Accordion
              mTitle='View WakeNetwork Acceptance Use Policy'
              sSize='smallUnderline'
            >
              <TermsOfUse />
            </Accordion>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default TermsOfUseAcceptanceForm;
