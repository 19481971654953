import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { object, string } from 'yup';

import LineBreaksToParagraphs from 'components/Layout/LineBreaksToParagraphs';
import * as scholarshipsActions from '../redux/actions';
import AddNote from '../../Form/OptionsModal/DefaultFields/AddNote';
import SectionHeader from '../SectionHeader';

const ScholarshipBiography = ({ biographyData }) => {
  const [isBioAddNoteActive, setIsBioAddNoteActive] = useState(false);
  const [addNoteStatus, setAddNoteStatus] = useState('');
  const dispatch = useDispatch();
  const biographyId = biographyData !== null ? biographyData.NOTEID : '';
  const biography = biographyData?.NOTE || '';
  const bioSchema = object({
    TEXTNOTE: string().max(10000).required('Please enter a biography.'),
  });

  const handleSaveBio = async ({ data }) => {
    try {
      await new Promise((resolve, reject) => {
        if (biography) {
          dispatch(
            scholarshipsActions.update({
              ID: biographyId,
              data: {
                TEXTNOTE: data.TEXTNOTE,
              },
              resolve,
              reject,
            })
          );
        } else {
          dispatch(
            scholarshipsActions.addNote({
              TITLE: 'Biography',
              TEXTNOTE: data.TEXTNOTE,
              NOTETYPECODEID: 'D7F63135-70BC-4CB6-B3BD-CE7AA1345AF4',
              resolve,
              reject,
            })
          );
        }
      });
      setIsBioAddNoteActive(false);
    } catch (err) {
      console.error('Error saving scholarships Biography Note.', err);
    }
  };

  const onClickCancel = () => {
    setIsBioAddNoteActive(!isBioAddNoteActive);
  };

  return (
    <>
      <SectionHeader
        sTitle='My Bio'
        fnHandleEditButtonClick={() =>
          setIsBioAddNoteActive(!isBioAddNoteActive)
        }
        bIsEditing={isBioAddNoteActive}
        bIsEditable
      />
      {!isBioAddNoteActive && !biography && (
        <div className='scholarships__missingBioStatement'>
          Click the pencil button to add to your bio and share a little about
          yourself with your donor.
        </div>
      )}
      {isBioAddNoteActive && (
        <AddNote
          visible={isBioAddNoteActive}
          setVisible={setIsBioAddNoteActive}
          onSubmit={handleSaveBio}
          status={addNoteStatus}
          setFormStatus={setAddNoteStatus}
          successMsg='Your Biography has been saved successfully.'
          initialValue={biography}
          classname='scholarships__bioEdit'
          formClassName='scholarships__textarea'
          textAreaClassName='scholarships__textarea'
          submitClassName='scholarships__editNote__submitBtn'
          showCancel
          onClickCancel={onClickCancel}
          saveButtonText='Save'
          validationSchema={bioSchema}
          sNoteFieldId='biography'
        />
      )}
      {!isBioAddNoteActive && biography && (
        <LineBreaksToParagraphs
          sText={biography}
          sClassName='scholarships__bio'
        />
      )}
    </>
  );
};

ScholarshipBiography.propTypes = {
  biographyData: PropTypes.shape({
    DATEADDED: PropTypes.string,
    NOTE: PropTypes.string,
    NOTEID: PropTypes.string,
  }),
};

ScholarshipBiography.defaultProps = {
  biographyData: {},
};

export default ScholarshipBiography;
